/*eslint-disable react-perf/jsx-no-jsx-as-prop */
import React from 'react'
import { CssBaseline, Box } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import { Routes, Route } from 'react-router-dom'
import { ApolloProvider } from '@apollo/client'
import { SnackbarProvider, type SnackbarOrigin } from 'notistack'

import client from './utils/client'
import { useCreateTheme } from './utils/theme'
import PortalProvider from './components/PortalProvider'
import Footer from './components/Footer'
import AppBar from './components/AppBar'

import getRoutes from './routes'

const anchorOrign = {
	horizontal: 'center',
	vertical: 'bottom',
} as SnackbarOrigin

const Navigator = () => {
	const routes = React.useMemo(() => getRoutes('LOGGED_OUT'), [])

	return (
		<Box minHeight="100vh" width="100%" display="flex" flexDirection="column" alignItems="center">
			<CssBaseline />
			<>
				<AppBar />
				<Routes>
					{routes.map(({ path, element }) => (
						<Route key={path} path={path} element={element} />
					))}
				</Routes>
				<Footer />
			</>
		</Box>
	)
}

const App = () => {
	const theme = useCreateTheme()
	return (
		<ApolloProvider client={client}>
			<ThemeProvider theme={theme}>
				<SnackbarProvider maxSnack={1} anchorOrigin={anchorOrign}>
					<PortalProvider>
						<Navigator />
					</PortalProvider>
				</SnackbarProvider>
			</ThemeProvider>
		</ApolloProvider>
	)
}

export default App
