import { ApolloClient, ApolloLink, InMemoryCache, HttpLink, from } from '@apollo/client'
import { onError } from '@apollo/client/link/error'

import { DEVELOPMENT, API_BASE_URL_GQL } from '../consts'
import Dialog from '../components/Dialog'
import { getToken } from './user'

const httpLink = new HttpLink({
	uri: API_BASE_URL_GQL,
})

const authLink = new ApolloLink((operation, forward) => {
	operation.setContext({ headers: { Authorization: `Bearer ${getToken()}` } })
	return forward(operation)
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
	if (graphQLErrors) {
		graphQLErrors.forEach((error) => {
			if (DEVELOPMENT) {
				console.log('DEV info: ErrorLink', error)
			}
			if (error?.extensions?.code === 'INTERNAL_SERVER_ERROR') {
				Dialog.render({
					title: 'Unerwarteter Fehler',
					description: 'Ein unerwarteter Fehler ist aufgetreten. Bitte kontaktiere den Support.',
				})
			}
		})
	}
	if (networkError) {
		Dialog.render({
			title: 'Netzwerkfehler',
			description: 'Es gibt Probleme mit ihrer Internetverbindung.',
		})
	}
})

const client = new ApolloClient({
	cache: new InMemoryCache({}),
	link: from([errorLink, authLink, httpLink]),
	defaultOptions: {
		watchQuery: { errorPolicy: 'all', fetchPolicy: 'cache-first' },
		query: { errorPolicy: 'all', fetchPolicy: 'cache-first' },
		mutate: { errorPolicy: 'all' },
	},
})

export default client
