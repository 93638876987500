import React from 'react'
import { gql, useQuery } from '@apollo/client'

import { UserInfoFragment } from './fragments'
import client from './client'

export type CurrentUser = {
	id: string
	email?: string
	firstName?: string
	lastName?: string
	isTestUser?: boolean
	emailConfirmed?: boolean
}

export const getToken = () => {
	return localStorage.getItem('jwt')
}

export const setToken = (jwt: string) => {
	return localStorage.setItem('jwt', jwt)
}

export const CURRENT_USER_QUERY = gql`
	query CurrentUser {
		currentUser {
			...UserInfoFragment
		}
	}
	${UserInfoFragment}
`

export const fetchUser = async (): Promise<void> => {
	await client.resetStore()
	await client.query({
		query: CURRENT_USER_QUERY,
		fetchPolicy: 'network-only',
	})
}

export const logout = async (navigate) => {
	localStorage.removeItem('jwt')
	await client.resetStore()
	navigate('/')
}

export const useCurrentUser = (): undefined | null | CurrentUser => {
	const { data } = useQuery(CURRENT_USER_QUERY)
	return React.useMemo(() => data?.currentUser, [data?.currentUser])
}
