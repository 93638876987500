import React from 'react';
import { debounce } from 'lodash';
import { useTheme } from '@mui/material/styles';
import { useNavigate as useNavigateReactRouterDom } from 'react-router-dom';
import { useActiveBreakPoint } from './theme';

const getWindowDimensions = () => {
	const { innerWidth: width, innerHeight: height } = window;
	return {
		width,
		height,
	};
};

export const useWindowDimensions = (): { width: number; height: number } => {
	const [windowDimensions, setWindowDimensions] = React.useState(getWindowDimensions());

	const handleResize = React.useCallback(() => {
		setWindowDimensions(getWindowDimensions());
	}, []);

	React.useEffect(() => {
		setWindowDimensions(getWindowDimensions());
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, [handleResize]);

	return windowDimensions;
};

type DivDimensions = {
	bottom?: number;
	height?: number;
	left?: number;
	right?: number;
	top?: number;
	width?: number;
};

export const useDivDimensions = (
	ref: React.RefObject<HTMLDivElement>,
	event: 'scroll' | 'resize' | 'scroll&resize' = 'scroll&resize',
	deps?: unknown[],
	debounceMs?: number
): DivDimensions => {
	const [rect, setRect] = React.useState<DivDimensions>({
		bottom: undefined,
		height: undefined,
		left: undefined,
		right: undefined,
		top: undefined,
		width: undefined,
	});

	const handleMeasure = React.useCallback(() => {
		if (ref?.current) {
			setRect(ref.current.getBoundingClientRect());
		}
	}, [ref]);

	const debouncedHandleMeasure = React.useMemo(
		() => debounce(handleMeasure, debounceMs || 300),
		[debounceMs, handleMeasure]
	);

	React.useEffect(() => {
		if (event.includes('scroll')) {
			window.addEventListener('scroll', debounceMs ? debouncedHandleMeasure : handleMeasure);
		}
		if (event.includes('resize')) {
			window.addEventListener('resize', debounceMs ? debouncedHandleMeasure : handleMeasure);
		}
		handleMeasure();
		return () => {
			if (event.includes('scroll')) {
				window.removeEventListener('scroll', debounceMs ? debouncedHandleMeasure : handleMeasure);
			}
			if (event.includes('resize')) {
				window.removeEventListener('resize', debounceMs ? debouncedHandleMeasure : handleMeasure);
			}
		};
	}, [debouncedHandleMeasure, event, handleMeasure, ref, deps, debounceMs]);

	return rect;
};

export const hexToRgba = (hex: string, opacity: number) => {
	const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
	const [r, g, b] = result
		? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)]
		: [0, 0, 0];
	return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

export const usePxPerRemFactor = (): number => {
	const theme = useTheme();
	const pxPerRem = 1 / Number(theme.typography.pxToRem(1).replace('rem', ''));
	return pxPerRem;
};

// TODO: replace with react-router-dom useNavigate
export const useNavigate = () => {
	const navigate = useNavigateReactRouterDom();
	return React.useCallback(
		(route) => {
			navigate(route);
		},
		[navigate]
	);
};

//eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
export const useInitializeStateWithDefault = <T extends any>({
	defaultValue,
	finishedInitializing,
}: {
	defaultValue: T;
	finishedInitializing?: boolean;
}): [T | undefined, React.Dispatch<React.SetStateAction<T | undefined>>, boolean] => {
	const [state, setState] = React.useState<T | undefined>(defaultValue);
	const [isInitialized, setIsInitialized] = React.useState(false);

	React.useEffect(() => {
		if (finishedInitializing) {
			setState(defaultValue);
			setIsInitialized(true);
		}
	}, [finishedInitializing, defaultValue]);

	const result = React.useMemo(() => [state, setState, isInitialized], [isInitialized, state]);
	return result as [T | undefined, React.Dispatch<React.SetStateAction<T | undefined>>, boolean];
};

export const useIsMobile = (): boolean => {
	const activeBreakpoint = useActiveBreakPoint();
	const isMobile = activeBreakpoint === 'xs' || activeBreakpoint === 'sm';
	return isMobile;
};

export const useDialogWidthPx = (): string => {
	const MOBILE_WIDTH_REM = 25;
	const DESKTOP_WIDTH_REM = 50;
	const pxPerRem = usePxPerRemFactor();
	const mobileWidthPx = MOBILE_WIDTH_REM * pxPerRem;
	const desktopWidthPx = DESKTOP_WIDTH_REM * pxPerRem;
	const { width: windowWidthPx } = useWindowDimensions();
	const isMobile = useIsMobile();
	const dialogWidth = !isMobile
		? desktopWidthPx
		: Math.min(mobileWidthPx, windowWidthPx - 2 * pxPerRem);
	return `${dialogWidth}px`;
};

export const useDialogDimensions = (): { width: string; maxHeight: string } => {
	const MOBILE_WIDTH_REM = 25;
	const DESKTOP_WIDTH_REM = 50;
	const pxPerRem = usePxPerRemFactor();
	const mobileWidthPx = MOBILE_WIDTH_REM * pxPerRem;
	const desktopWidthPx = DESKTOP_WIDTH_REM * pxPerRem;
	const { width: windowWidthPx, height: windowHeight } = useWindowDimensions();
	const isMobile = useIsMobile();
	const dialogWidth = !isMobile
		? desktopWidthPx
		: Math.min(mobileWidthPx, windowWidthPx - pxPerRem);

	const MOBILE_HEIGHT_REM = 30;
	const DESKTOP_HEIGHT_REM = 60;
	const mobileHeightPx = MOBILE_HEIGHT_REM * pxPerRem;
	const desktopHeightPx = DESKTOP_HEIGHT_REM * pxPerRem;
	const dialogHeight = Math.min(
		!isMobile ? desktopHeightPx : mobileHeightPx,
		windowHeight - 2 * pxPerRem
	);

	return { width: `${dialogWidth}px`, maxHeight: `${dialogHeight}px` };
};

export const useGrouped = ({
	items,
	nGroups,
}: {
	items?: unknown[];
	nGroups: number;
}): Array<unknown[]> => {
	const initial = Array.from(Array(nGroups).keys()).map(() => []) as Array<unknown[]>;
	const grouped = React.useMemo(
		() =>
			items?.length
				? items.reduce<Array<unknown[]>>((acc, next) => {
						const minimumNumberOfItems = acc
							.map((a) => a.length)
							.sort((a, b) => (a > b ? 1 : -1))[0];
						const arrayToPushTo = acc.findIndex((a) => a.length === minimumNumberOfItems);
						acc[arrayToPushTo].push(next);
						return acc;
				  }, initial)
				: [[]],
		[initial, items]
	);
	return grouped;
};

export const useLabelAndValue = ({
	items,
	labelKey,
	valueKey,
}: {
	items?: { [key: string]: string | unknown }[] | null;
	labelKey: string;
	valueKey: string;
}): { label: string; value: string }[] | undefined => {
	return React.useMemo(
		() =>
			items?.map(
				(item) =>
					({
						label: typeof item[labelKey] === 'string' ? item[labelKey] : '?unknown',
						value: typeof item[valueKey] === 'string' ? item[valueKey] : '?unknown',
					} as { label: string; value: string })
			),
		[items, labelKey, valueKey]
	);
};

export const getValidArray = <T>(array: T[] | undefined | null): T[] | undefined | null =>
	!array || !Array.isArray(array) ? undefined : array.length === 0 ? null : array.filter(Boolean);
// TODO: remove this!
export const useValidArray = <T>(array: T[] | undefined | null): T[] | undefined | null =>
	React.useMemo(() => getValidArray(array), [array]);
