import * as React from 'react'
import { Accordion, AccordionDetails, AccordionSummary, TypographyProps } from '@mui/material'
import { Box } from '@mui/material'
import { KeyboardArrowDown } from '@mui/icons-material'
import Txt from './Txt'
import { useIsMobile } from '../utils/hooks'

const Expandable = ({
	id,
	label,
	rightLabel,
	labelVariant,
	children,
	m,
	defaultExpanded = false,
	expandedValue,
	onChange,
	renderLabel,
	disabled,
	maxWidth,
	onClick,
	hideElevation,
	hasBoldTitle,
}: {
	id?: string
	label?: string
	rightLabel?: string
	labelVariant?: TypographyProps['variant']
	children: JSX.Element | JSX.Element[]
	m?: string
	defaultExpanded?: boolean
	expandedValue?: boolean
	onChange?: (changedId: string) => void
	renderLabel?: (str?: string) => JSX.Element
	disabled?: boolean
	maxWidth?: string
	onClick?: (changedId?: string) => void
	hideElevation?: boolean
	hasBoldTitle?: boolean
}): JSX.Element => {
	const [expanded, setExpanded] = React.useState<boolean>(defaultExpanded)

	const handleToggleExpanded = React.useCallback(() => {
		if (expandedValue !== undefined) {
			//controlled component
			onChange?.(id!)
		} else {
			// uncontrolled component
			setExpanded((b) => !b)
		}
	}, [id, onChange, expandedValue])

	const handleClick = React.useCallback(() => {
		onClick?.(id)
	}, [id, onClick])

	const isMobile = useIsMobile()

	return (
		<Box m={m} maxWidth={maxWidth}>
			<Accordion
				expanded={expandedValue !== undefined ? expandedValue : expanded}
				onChange={handleToggleExpanded}
				disabled={disabled}
				elevation={hideElevation ? 0 : undefined}
			>
				<Box onClick={handleClick}>
					<AccordionSummary
						// eslint-disable-next-line react-perf/jsx-no-jsx-as-prop
						expandIcon={<KeyboardArrowDown />}
					>
						{renderLabel ? (
							renderLabel()
						) : (
							<Box
								display="flex"
								flexDirection={isMobile ? 'column' : 'row'}
								justifyContent="space-between"
								width="100%"
								m="0 1rem 0 0"
							>
								<Txt fontWeight={hasBoldTitle ? 'bold' : undefined} variant={labelVariant}>
									{label}
								</Txt>
								{rightLabel ? <Txt variant={labelVariant}>{rightLabel}</Txt> : null}
							</Box>
						)}
					</AccordionSummary>
				</Box>

				<AccordionDetails
					style={{
						margin: 0,
						padding: 0,
					}}
				>
					{children}
				</AccordionDetails>
			</Accordion>
		</Box>
	)
}

export default React.memo(Expandable)
