import React from 'react'
import { Box } from '@mui/material'
import ReactMarkdown from 'react-markdown'

import Txt from './Txt'
import { BoxProps } from '@mui/system'

const MarkdownTxt = ({
	children: markdownTextChildren,
	noMargin,
	...rest
}: {
	noMargin?: boolean
	children: string
} & BoxProps) => {
	// Normally we apply a margin to each paragraph. This leads also to a margin below the last paragraph. This is bad for short texts
	// where the additional margin breaks alignment. (e.g. Checkbox2).

	const margingString = noMargin ? '0' : '0 0 1rem 0'
	const components = React.useMemo(
		() => ({
			p: ({ children, ...props }) => (
				<Txt variant="body1" m={margingString} {...props}>
					{children}
				</Txt>
			),
			h1: ({ children, ...props }) => (
				<Txt variant="h1" m={margingString} {...props}>
					{children}
				</Txt>
			),
			h2: ({ children, ...props }) => (
				<Txt variant="h2" m={margingString} {...props}>
					{children}
				</Txt>
			),
			h3: ({ children, ...props }) => (
				<Txt variant="h3" m={margingString} {...props}>
					{children}
				</Txt>
			),
			h4: ({ children, ...props }) => (
				<Txt variant="h4" m={margingString} {...props}>
					{children}
				</Txt>
			),
			h5: ({ children, ...props }) => (
				<Txt variant="h5" m={margingString} {...props}>
					{children}
				</Txt>
			),
			h6: ({ children, ...props }) => (
				<Txt variant="h6" m={margingString} {...props}>
					{children}
				</Txt>
			),
			strong: ({ children, ...props }) => {
				return (
					<Txt span fontWeight="bold" {...props}>
						{children}
					</Txt>
				)
			},
			a: ({ children, ...props }) => {
				return (
					<Txt span {...props} navigateTo={props.href}>
						{children}
					</Txt>
				)
			},
		}),
		[margingString]
	)

	return (
		<Box {...rest}>
			<ReactMarkdown components={components}>{markdownTextChildren}</ReactMarkdown>
		</Box>
	)
}

export default React.memo(MarkdownTxt)
