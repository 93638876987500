import React from 'react'
import ContentWrapper from '../components/ContentWrapper'
import ImageBox from '../components/ImageBox'
import { HERO_MAGE, SEVEN_PHONES } from '../consts'
import { Box, Grid } from '@mui/material'
import Txt from '../components/Txt'
import { useTheme } from '@mui/material/styles'
import GridItem from '../components/GridItem'
import { useActiveBreakPoint } from '../utils/theme'
import { faqEntries, teaserItems, appScreenItems, aboutUsItems } from '../utils/texts'
import Expandable from '../components/Expandable'
import MarkdownTxt from '../components/MarkdownTxt'
import DownloadSection from '../components/DownloadSection'

const ImageArticle = ({
	imageUrl,
	title,
	description,
	type = 'left',
	link,
	m,
	altBackgroundColor,
	p,
	objectFit = 'contain',
	maxHeight,
}: {
	imageUrl: string
	title: string
	description: string
	type?: 'left' | 'right'
	link?: string
	m?: string
	altBackgroundColor?: boolean
	p?: string
	objectFit?: 'contain' | 'cover'
	maxHeight?: string
}) => {
	const theme = useTheme()
	const { responsiveRem: rr } = theme
	const activeBreakPoint = useActiveBreakPoint()

	const isVerySmall = activeBreakPoint === 'xs' || activeBreakPoint === 'sm'

	const GriddedImage = React.useCallback(
		() => (
			<GridItem
				xs={12}
				sm={6}
				md={6}
				lg={4}
				bgcolor={
					altBackgroundColor ? theme.palette.common.white : theme.customColors.primaryElevation2
				}
			>
				<ImageBox
					src={imageUrl}
					objectFit={objectFit}
					height="100%"
					width="100%"
					maxHeight={maxHeight}
					p={p}
				/>
			</GridItem>
		),
		[
			altBackgroundColor,
			imageUrl,
			maxHeight,
			objectFit,
			p,
			theme.customColors.primaryElevation2,
			theme.palette.common.white,
		]
	)

	return (
		<Box width="100%" m={m}>
			<Grid container>
				{isVerySmall ? null : type === 'left' ? <GriddedImage /> : null}

				<GridItem xs={12} sm={6} md={6} lg={8}>
					<Box width="100%" height="100%" p={rr('2rem')}>
						<Txt variant="h2" m={rr('0 0 1rem 0')} fontWeight="bold">
							{title}
						</Txt>

						<Txt variant="h3" key={description} m={rr('0 0 0.5rem 0')}>
							{description}
						</Txt>

						{link ? (
							<Txt m="2rem 0 0 0" variant="h5" noUnderLine navigateToExternal navigateTo={link}>
								Quelle
							</Txt>
						) : null}
					</Box>
				</GridItem>
				{isVerySmall || type === 'right' ? <GriddedImage /> : null}
			</Grid>
		</Box>
	)
}

const SectionHeader = ({ title }: { title: string }) => {
	const theme = useTheme()
	const { responsiveRem: rr } = theme

	return (
		<Box display="flex" justifyContent="center" alignItems="center" p={rr('3rem')} width="100%">
			<Txt variant="h1" color={theme.customColors.accent} align="center" fontWeight="bold">
				{title}
			</Txt>
		</Box>
	)
}

const HomeView = (): JSX.Element => {
	const theme = useTheme()
	const { responsiveRem: rr } = theme

	return (
		<>
			<ContentWrapper>
				<ImageBox
					src={HERO_MAGE}
					objectFit="cover"
					minHeight="20rem"
					minWidth="20rem"
					maxHeight="50rem"
				>
					<Box
						width="100%"
						height="100%"
						position="absolute"
						display="flex"
						alignItems="center"
						justifyContent="center"
					>
						<Box bgcolor={theme.customColors.lightBackground} p={rr('2rem')} m={rr('2rem')}>
							<Txt variant="h1" align="center">
								Mindestbestellwert zusammen erreichen - grenzenlos genießen!
							</Txt>
						</Box>
					</Box>
				</ImageBox>
				<>
					{teaserItems.map(({ image, title, description }, index) => (
						<ImageArticle
							type={index % 2 === 0 ? 'left' : 'right'}
							key={image}
							imageUrl={image}
							title={title}
							description={description}
							m={rr('1rem 0')}
						/>
					))}
				</>
				<DownloadSection />

				<SectionHeader title="Wie funktioniert Ubimeal?" />
				<ImageBox src={SEVEN_PHONES} objectFit="contain" />
				<>
					{appScreenItems.map(({ image, title, description, p, maxHeight }, index) => (
						<ImageArticle
							type={index % 2 === 0 ? 'right' : 'left'}
							key={image}
							imageUrl={image}
							title={title}
							description={description}
							maxHeight={maxHeight}
							m={rr('1rem 0')}
							p={rr(p)}
						/>
					))}
				</>
				<DownloadSection />

				<>
					{aboutUsItems.map(({ image, title, description, altBackgroundColor, p }, index) => (
						<ImageArticle
							type={index % 2 === 0 ? 'right' : 'left'}
							key={image}
							imageUrl={image}
							title={title}
							description={description}
							altBackgroundColor={altBackgroundColor}
							m={rr('1rem 0')}
							p={rr(p)}
						/>
					))}
				</>

				<SectionHeader title="Fragen?" />
				<Box p={rr('0 3rem')}>
					{faqEntries.map(({ title, description }) => (
						<Box key={title}>
							<Expandable label={title} key={title} m="0 0 0.5rem 0" maxWidth="100%">
								<Box p="1rem">
									<MarkdownTxt>{description}</MarkdownTxt>
								</Box>
							</Expandable>
						</Box>
					))}
				</Box>

				<DownloadSection />
			</ContentWrapper>
		</>
	)
}

export default HomeView
