import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Grid } from '@mui/material';

import { useActiveBreakPoint } from '../utils/theme';

type BreakPointLabel = 'xs' | 'sm' | 'md' | 'lg' | 'xl';
type Spacing = { [key in BreakPointLabel]?: number };

const defaultSpacing = { xs: 1, sm: 4, md: 8, lg: 8, xl: 8 };

type ReturnType = JSX.Element | null;

const GridContainer = ({
	spacing = defaultSpacing,
	horizontalSpacing,
	verticalSpacing,
	children,
	excludePadding,
	p,
	m,
	border,
}: {
	spacing?: Spacing;
	horizontalSpacing?: Spacing;
	verticalSpacing?: Spacing;
	children: ReturnType | ReturnType[];
	excludePadding?: boolean;
	p?: string;
	m?: string;
	border?: string;
}): JSX.Element => {
	const theme = useTheme();

	const [mergedSpacing, mergedHorizontalSpacing, mergedVerticalSpacing] = React.useMemo(
		() => [
			{ ...defaultSpacing, ...spacing },
			{ ...defaultSpacing, ...horizontalSpacing },
			{ ...defaultSpacing, ...verticalSpacing },
		],
		[verticalSpacing, horizontalSpacing, spacing]
	);
	const activeBreakPoint = useActiveBreakPoint();
	const separateSpacings = React.useMemo(
		() => Boolean(horizontalSpacing || verticalSpacing),
		[verticalSpacing, horizontalSpacing]
	);

	const [verticalPadding, horizontalPadding]: [string, string] = React.useMemo(() => {
		if (separateSpacings) {
			const currentHorizontalSpacing = mergedHorizontalSpacing[activeBreakPoint];
			const currentVerticalSpacing = mergedVerticalSpacing[activeBreakPoint];
			return [theme.spacing(currentVerticalSpacing), theme.spacing(currentHorizontalSpacing)];
		} else {
			const currentSpacing = mergedSpacing[activeBreakPoint];
			return [theme.spacing(currentSpacing), theme.spacing(currentSpacing)];
		}
	}, [
		activeBreakPoint,
		mergedVerticalSpacing,
		mergedHorizontalSpacing,
		mergedSpacing,
		separateSpacings,
		theme,
	]);
	return (
		<Box
			border={border}
			width="100%"
			padding={p ? p : excludePadding ? undefined : `${verticalPadding} ${horizontalPadding}`}
			m={m}
		>
			<Grid
				container
				spacing={!separateSpacings ? mergedSpacing : undefined}
				rowSpacing={separateSpacings ? mergedVerticalSpacing : undefined}
				columnSpacing={separateSpacings ? mergedHorizontalSpacing : undefined}
			>
				{children}
			</Grid>
		</Box>
	);
};

export default React.memo(GridContainer);
