import React from 'react'
import { styled } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'
import { Typography, Box } from '@mui/material'
import type { TypographyProps } from '@mui/material/Typography'

const TxtLimit = styled('div')<{ numberoflines: number }>(({ numberoflines }) => ({
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	display: '-webkit-box',
	WebkitLineClamp: numberoflines,
	WebkitBoxOrient: 'vertical',
}))

export interface TxtProps extends TypographyProps {
	label?: string
	m?: string
	numberOfLines?: number
	lineBreaks?: boolean
	flex?: string
	navigateTo?: string
	navigateToExternal?: boolean
	onClick?: () => void
	noUnderLine?: boolean
	color?: string
	span?: boolean
	id?: string
}

const StyledBox = styled(Box)<{
	clickable?: 'true' | 'false'
	removeunderline?: 'true' | 'false'
	color?: string
}>(({ clickable, removeunderline, color }) => ({
	cursor: clickable === 'true' ? 'pointer' : '',
	textDecoration: clickable === 'true' && removeunderline === 'false' ? 'underline' : '',
	textDecorationColor: color,
}))

const Txt = ({
	label,
	m,
	children,
	numberOfLines,
	lineBreaks,
	flex,
	navigateTo,
	navigateToExternal,
	onClick,
	noUnderLine,
	color,
	span, // this is necessary to support for markdown
	width,
	...rest
}: TxtProps) => {
	const navigate = useNavigate()
	const handleNavigate = React.useCallback(() => {
		if (navigateTo?.includes('_blank')) {
			const path = navigateTo.replace('_blank', '')
			window.open(path, '_blank')
		} else if (navigateTo?.includes('mailto:')) {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			window.location = navigateTo
		} else if (navigateToExternal) {
			window.open(navigateTo!, '_blank')
		} else {
			navigate(navigateTo!)
		}
	}, [navigate, navigateTo, navigateToExternal])

	const handleClick = React.useCallback(() => {
		onClick?.()
	}, [onClick])

	return (
		<StyledBox
			m={m}
			flex={flex}
			whiteSpace={lineBreaks ? 'pre-line' : 'normal'}
			component={span ? 'span' : 'div'} // this is necessary to support for markdown
			width={width}
			onClick={navigateTo ? handleNavigate : handleClick}
			color={color}
			clickable={navigateTo || onClick ? 'true' : 'false'}
			removeunderline={noUnderLine ? 'true' : 'false'}
		>
			{numberOfLines ? (
				<TxtLimit numberoflines={numberOfLines}>
					<Typography component={span ? 'span' : 'div'} color={color} {...rest}>
						{label || children}
					</Typography>
				</TxtLimit>
			) : (
				<Typography
					component={span ? 'span' : 'div'} // this is necessary to support for markdown
					color={color}
					{...rest}
				>
					{label || children}
				</Typography>
			)}
		</StyledBox>
	)
}

export default React.memo(Txt)
