import React from 'react';
import { styled } from '@mui/material/styles';
import { Box, CircularProgress } from '@mui/material';
import MuiIconButton from '@mui/material/IconButton';
import type { IconButtonProps as MuiIconButtonProps } from '@mui/material/IconButton';
import { useNavigate } from 'react-router-dom';

import iconNameDict, { IconName } from '../utils/iconNameDict';

const StyledIconButton = styled(MuiIconButton)<{ bgcolor?: string; iconcolor?: string }>(
	({ bgcolor, iconcolor }) => ({
		backgroundColor: bgcolor || '',
		color: iconcolor || '',
		margin: 0,
		padding: 0,
	})
);

const IconButton = ({
	id,
	m,
	p,
	loading,
	color,
	onClick,
	iconName,
	navigateTo,
	iconColor,
	backgroundColor,
	...rest
}: {
	id?: string;
	m?: string;
	p?: string;
	loading?: boolean;
	color?: string;
	onClick?: (val?: any) => any;
	iconName: IconName;
	navigateTo?: string;
	iconColor?: string;
	backgroundColor?: string;
} & Omit<MuiIconButtonProps, 'color'>): JSX.Element => {
	const navigate = useNavigate();
	const handleClick = React.useCallback(() => {
		if (navigateTo) {
			navigate(navigateTo);
		} else {
			onClick?.(id);
		}
	}, [id, navigate, navigateTo, onClick]);

	const Icon = iconName && iconNameDict[iconName] ? iconNameDict[iconName] : null;

	return (
		<Box m={m} p={p} position="relative">
			<StyledIconButton
				{...rest}
				bgcolor={backgroundColor}
				iconcolor={iconColor}
				onClick={handleClick}
			>
				{loading ? (
					// eslint-disable-next-line react-perf/jsx-no-new-object-as-prop
					<CircularProgress size={24} sx={{ color }} />
				) : Icon ? (
					<Icon />
				) : null}
			</StyledIconButton>
		</Box>
	);
};

export default React.memo(IconButton);
