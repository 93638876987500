import React from 'react'
import ImageBox from './ImageBox'
import { COMPANY_LOGO, COMPANY_LOGO_AND_TEXT, COMPANY_TEXT } from '../consts'

const Logo = ({
	width = '100%',
	maxWidth = '3rem',
	height,
	navigateTo,
	m,
	type,
}: {
	width?: string
	maxWidth?: string
	height?: string
	navigateTo?: string
	m?: string
	type: 'LOGO' | 'LOGO_AND_TEXT' | 'TEXT'
}): JSX.Element => {
	return (
		<ImageBox
			navigateTo={navigateTo}
			src={
				type === 'LOGO'
					? COMPANY_LOGO
					: type === 'LOGO_AND_TEXT'
					? COMPANY_LOGO_AND_TEXT
					: COMPANY_TEXT
			}
			width={width}
			maxWidth={maxWidth}
			height={height}
			m={m}
			objectFit="contain"
		/>
	)
}

export default React.memo(Logo)
