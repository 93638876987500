import { companyName } from '../consts'

const imageDict = {
	ORDER_DEMOGRAPHICS: 'orderDemographics.png',
	HERO_MAGE: '/burger-1-min.png',
	STEP_1: '/step1-min.png',
	STEP_2: '/step2-min.png',
	STEP_3: '/step3-min.png',
	SEVEN_PHONES: '/7screens-min.png',
	MIN_ORDER_VALUE_RATIO: '/minOrderValueRationBrb.png',
	AVG_MIN_ORDER_VALUES_BRB: '/avgMinOrderValueBrb.png',
}

export const faqEntries = [
	{
		title: `Wann kann ich ${companyName} nutzen?`,
		description: `${companyName} befindet sich aktuell in der Testphase, wann wir offiziell starten, hängt von den Testergebnissen ab. Wenn du auf dem laufenden bleiben möchtest, würde ich mich freuen, wenn du dich für den Newsletter anmelden würdest.`,
	},
	{
		title: `Wie funktioniert ${companyName}?`,
		description: `${companyName} ist wie jede andere Food Delivery App mit einem kleinen aber feinen Unterschied. Mit ${companyName} können Bewohner einer Gemeinde eine gemeinsame Sammelbestellung bei einem Restaurant aufgeben, um die häufig hohen Mindestbestellwerte zu erreichen.`,
	},
	{
		title: 'Muss ich mich mit meinen Nachbarn absprechen?',
		description:
			'Nein, es ist keine Absprache erforderlich. Du buchst dich in einen bestehenden Liefertermin ein und wartest, bis zwei weitere Nachbarn aus der Gemeinde beigetreten sind. Dann kann die Bestellung an das Restaurant übermittelt werden.',
	},
	{
		title: 'Können meine Nachbarn sehen, was ich bestellt habe?',
		description:
			'Nein, nur das Restaurant kann sehen, wer was bestellt hat. Nutzer können nur sehen, dass sich jemand einer Bestellung angeschlossen hat, nicht aber wer es war und auch nicht was bestellt wurde.',
	},
	{
		title: 'Können alle Bewohner einer Gemeinde mitmachen?',
		description: `Damit die Lieferung wirtschaftlich bleibt, können aktuell nur Adressen berücksichtig werden, die sich i.d.R. innerhalb eines 2km Radius des Gemeindezentrums befinden. Zu einem späteren Zeitpunkt werden wir auch weiter entfernte Adressen erreichen können.`,
	},
	{
		title: `Wie hoch ist der Mindestbestellwert bei ${companyName} im Vergleich zu anderen Anbietern?`,
		description: `Der Mindestbestellwert beträgt in etwa ein Drittel des sonst üblichen Mindesbestellwerts, da sich immer drei Nachbarn eine Bestellung teilen. Aus 60 Euro werden so schnell erschwingliche 20 Euro!`,
	},
	{
		title: 'Ist es garantiert, dass meine Bestellung auch geliefert wird?',
		description:
			'Es müssen genau drei Nutzer für einen Lieferzeitpunkt beim selben Restaurant die Zahlung autorisiert haben, erst dann wird die Bestellung an das Restaurant übermittelt.',
	},
	{
		title: 'Wie lange haben meine Nachbarn Zeit sich meiner Bestellung anzuschließen?',
		description:
			'Nachdem der erste Nutzer für einen Lieferzeitpunkt die Zahlung autorisiert hat, können die Nachbarn bis spätestens 1,5 Stunden vor Liefertermin noch der Buchung beitreten.',
	},
	{
		title: 'Warum kann ich nicht spontan buchen?',
		description:
			'Da im ländlichen Raum die Menschen deutlich weniger dicht wohnen, als in der Stadt, ist es wahrscheinlich, dass spontane Buchungen eher scheitern. Dies liegt daran, dass sich innerhalb eines kurzen Zeitraum ggf. nicht die drei erforderlichen Nachbarn finden lassen, um die Bestellung auszulösen zu können. Dieses Feature soll aber später zumindest getestet werden.',
	},
	{
		title: 'Was passiert, wenn sich meiner Bestellung nicht zwei weitere Nutzer anschließen?',
		description:
			'In diesem Fall wird die Buchung storniert und die autorisierte Zahlung wird nicht eingelöst - dein Konto wird also nicht belastet.',
	},
	{
		title: 'Wann erfahre ich, ob die Buchung zustande kommt oder nicht?',
		description:
			'Sobald insgesamt drei Nutzer einer Bestellung beigetreten sind, erhalten alle Nutzer eine entsprechende Benachrichtigung. Wenn sich bis 1,5 Stunden vor Liefertermin nicht die erforderlichen drei Nutzer gefunden haben, wird die Buchung storniert und die autorisierte Zahlung wird nicht eingelöst.',
	},
	{
		title: `Was kostet mich ${companyName}?`,
		description:
			'Für jede Teilbestellung erheben wir eine Servicegebühr von 1 Euro für den Bestellenden.',
	},
	{
		title: 'Was passiert, wenn sich drei Nutzer für eine Bestellung zusammengefunden haben?',
		description:
			'Die Autorisierten Zahlungen werden gebucht und auf das Konto des Restaurants transferiert. Das Restaurant kann nun die Bestellung einplanen bzw. zubereiten.',
	},
	{
		title: 'Warum wird meine Gemeinde nicht beliefert?',
		description:
			'Wir sind noch ein junges Unternehmen und starten daher zunächst in wenigen Gemeinden. Wir arbeiten jedoch daran, unser Angebot stetig zu erweitern.',
	},
	{
		title: 'Warum liefert ihr zwar in meine Gemeinde, aber nicht an meine Adresse?',
		description: `Unser Service beschränkt sich derzeit auf Adressen, die sich in der Nähe des Gemeindezentrums befinden. 
			Wir möchten in Zukunft durch intelligentes Pooling aber auch etwas abgelegenere Wohnadressen erreichen.`,
	},
	// {
	// 	title: `Warum ist es für Restaurants interessant, ${companyName} zu nutzen?`,
	// 	description: `Weiter entfernte Adressen zu beliefern ist für Restaurants oft nur dann wirtschaftlich, wenn der Bestellwert entsprechend hoch ist.
	// 	Dadurch sind die Bewohner weit entfernter Gemeinden von der Lieferung faktisch häufig ausgeschlossen, da ein einzelner Haushalt selten für 50 Euro aufwärts bestellen wird.
	// 	Mit ${companyName} können Restaurants diesen Markt erschließen und ihren Umsatz steigern.`,
	// },
	// {
	// 	title: `Ist es für Restaurants rentabel, über ${companyName} zu liefern? Immerhin müssen ja drei Adressen je Bestellung beliefert werden und nicht eine.`,
	// 	description: `Ja, denn die Restaurants liefern in der Regel auch so an weiter entfernte Gemeinden, wenn Mindestbestellwert entsprechend hoch ist.
	// 	Dass die Fahrer drei anstatt zwei Adressen anfahren müssen, bedeutet zwar einen Mehraufwand, allerdings hält sich dieser in Grenzen, da ${companyName} nur Adressen in der Nähe der Gemeindezentren berücksichtgt, wodurch sich die Fahrtwege zwischen den Adressen in Grenzen halten.`,
	// },
]

// Zu guter Letzt ist die von den Restaurants an ${companyName} zu entrichtene Provision auf 1 Euro pro Teilbestellung gedeckelt. Bei einem Bestellvolumen von 100 Euro entspricht das einer Provision von 3%, was deutlich unter den Provisionen anderer Food Delivery Apps liegt.

export const teaserItems = [
	{
		image: '/step1-min.png',
		title: 'Mindestbestellwert zu hoch?',
		description:
			'Lust auf deinen Lieblingsburger, aber du willst nicht zehn mal extra Pommes bestellen, damit der 60 € Mindestbestellwert erreicht wird? Wir haben die Lösung!',
	},
	{
		image: '/step2-min.png',
		title: 'Bestellt zusammen!',
		description: `Die ${companyName} App findet für dich ganz einfach Leute in deiner Nähe, die das gleiche Problem haben. Gemeinsam spielt der Mindestbestellwert fortan keine große Rolle mehr.`,
	},
	{
		image: '/step3-min.png',
		title: 'Dein Essen ist da!',
		description:
			'Ab jetzt musst du auf kein Restaurant mehr verzichten auch wenn du nicht in Berlin wohnst. Dein Essen kommt wie gewohnt zu dir nach Hause. Guten!',
	},
]

export const problemItems = [
	{
		image: '/minOrderValueRationBrb.png',
		title: 'Durchschnittlicher Bestellwert bei 29€',
		description:
			'Laut Lieferando lag der durchschnittliche Bestellwert 2023 in Brandenburg bei 29€. Gleichzeitig haben über 40% aller Lieferando-Angebote in Brandenburg einen Mindestbestellwert der größer ist.',

		link: 'https://d21buns5ku92am.cloudfront.net/69464/documents/53644-1703070545-Food-trends-report-2023-de-6a5bfa.pdf',
		altBackgroundColor: true,
		p: '2rem',
	},
	{
		image: 'orderDemographics.png',
		title: 'Hohe Hürde',
		description: `Die hohen Mindestbestellwerte stellen für Singles und Paare (= 68% aller Kunden) eine hohe Hürde dar und führen dazu, dass diese auf Lieferdienste verzichten. Ein Single-Haushalt wird z.B. selten für mehr als 20€ bestellen, aber laut Lieferando bestellen 28% aller Kunden alleine.`,
		link: 'https://d21buns5ku92am.cloudfront.net/69464/documents/53644-1703070545-Food-trends-report-2023-de-6a5bfa.pdf',
		altBackgroundColor: true,
		p: '2rem',
	},
	{
		image: '/avgMinOrderValueBrb.png',
		title: 'Viele Menschen im ländlichen Raum betroffen',
		description:
			'Umso ländlicher die Region, desto weiter entfernt liegen die Restaurants und desto höher ist der durchschnittliche Mindestbestellwert in einer Gemeinde. In Brandenburg leben etwa 200.000 Menschen in Gemeinden, in die kein Restaurant unter 30€ Mindestbestellwert liefert.',
		altBackgroundColor: true,
		p: '2rem',
	},
]

export const appScreenItems = [
	// {
	// 	image: '/1.png',
	// 	title: 'Wähle deine Gemeinde',
	// 	description: 'asdasdasdsadas',
	// 	altBackgroundColor: true,
	// 	p: '2rem',
	// },
	// {
	// 	image: '/2.png',
	// 	title: 'Wähle deine Adresse',
	// 	description:
	// 		'Aktuell können wir nur Lieferadressen beliefern, die sich in etwa 5 Autominuten vom Gemeindezentrum entfernt befinden.',
	// 	altBackgroundColor: true,
	// 	p: '2rem',
	// },
	{
		image: '/3.png',
		title: 'Wähle das Restaurant',
		description: `Nachdem du deine Adresse ausgewählt hast, werden alle Restaurants angezeigt, die in deiner Gemeinde via ${companyName} liefern. Schaue dir den Mindestbestellwert genau an - er beträgt nur ein Drittel des sonst üblichen Mindestbestellwerts!`,
		altBackgroundColor: true,
		p: '2rem',
		maxHeight: '40rem',
	},
	{
		image: '/4.png',
		title: 'Wähle den Lieferzeitpunkt',
		description:
			'Jedes Restaurant hat feste Lieferzeiten festgelegt zu denen die Bestellungen ausgeliefert werden. Wähle den Lieferzeitpunkt, der dir am besten passt.',
		altBackgroundColor: true,
		p: '2rem',
		maxHeight: '40rem',
	},
	{
		image: '/5.png',
		title: 'Was willst du Essen?',
		description:
			'Worauf hast du Lust? Wähle aus, was du essen möchtest. Füge es deinem Warenkorb hinzu und bezahle mit Paypal.',
		altBackgroundColor: true,
		p: '2rem',
		maxHeight: '40rem',
	},
	// {
	// 	image: '/6.png',
	// 	title: 'Warenkorb',
	// 	description:
	// 		'Im Warenkorb siehst du, was du bestellt hast und wie viel es kostet. Du kannst hier auch noch Änderungen vornehmen.',
	// 	altBackgroundColor: true,
	// 	p: '2rem',
	// 	maxHeight: '40rem',
	// },
	// {
	// 	image: '/7.png',
	// 	title: 'Zahle mit PayPal',
	// 	description:
	// 		'Aktuell ist nur die Zahlung via PayPal möglich. Wir arbeiten daran, weitere Zahlungsmethoden zu integrieren.',
	// 	altBackgroundColor: true,
	// 	p: '2rem',
	// 	maxHeight: '40rem',
	// },
	// {
	// 	image: '/8.png',
	// 	title: '8',
	// 	description: 'asdasdasdsadas',
	// 	altBackgroundColor: true,
	// 	p: '2rem',
	// },
	{
		image: '/9.png',
		title: 'Du bist dabei!',
		description:
			'Du hast deinen Teil zur Bestellung beigetragen. Jetzt müssen nur noch zwei weitere Nachbarn dem Lieferzeitpunkt beitreten und die Bestellung kann an das Restaurant übermittelt werden.',
		altBackgroundColor: true,
		p: '2rem',
		maxHeight: '40rem',
	},
	// {
	// 	image: '/10.png',
	// 	title: '10',
	// 	description: 'asdasdasdsadas',
	// 	altBackgroundColor: true,
	// 	p: '2rem',
	// },
]

export const aboutUsItems = [
	{
		image: '/AndreasPhoto.png',
		title: 'Über mich',
		description:
			'Ich bin Andreas - promovierter Apotheker, Softwareentwickler und als Gründer von Ubimeal nun auch Unternehmer. Ich habe Ubimeal gegründet, um Menschen in ländlichen Regionen die Möglichkeit zu geben auch von der Vielfalt der Lieferdienste zu profitieren. Da ich selbst aus Berlin in eine eher ländliche Region gezogen bin, kenne ich das Problem aus eigener Erfahrung und habe mich entschlossen, es zu lösen.',
		altBackgroundColor: true,
		p: '2rem',
	},
]
