import React from 'react'
import ImageBox from './ImageBox'
import { Box } from '@mui/material'
import { useTheme } from '@mui/material/styles'

const BADGE_WIDTH_REM = 12

const DownloadSection = () => {
	const theme = useTheme()
	const { responsiveRem: rr } = theme

	return (
		<Box display="flex" justifyContent="center" alignItems="center" p={rr('3rem')} width="100%">
			<ImageBox
				src="/DownloadAndroidBadge.png"
				objectFit="contain"
				width={`${BADGE_WIDTH_REM}rem`}
				className="rm-open-popup"
			/>
			<Box width={rr('0.5rem')} />
			<ImageBox
				src="/DownloadIosBadge.svg"
				objectFit="contain"
				width={`${0.925 * BADGE_WIDTH_REM}rem`}
				className="rm-open-popup"
			/>
		</Box>
	)
}

export default DownloadSection
