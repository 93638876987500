import React from 'react'
import { createTheme } from '@mui/material/styles'
import type { ThemeOptions as MuiThemeOptions } from '@mui/material/styles'
import { useWindowDimensions } from './hooks'
import { breakpoints, contentMaxWidthPx } from '../consts'
import { BreakpointLabel } from '../../typescript-types'
import chroma from 'chroma-js'

declare module '@mui/material/Button' {
	interface ButtonPropsVariantOverrides {
		mainButton: true
		secondButton: true
		altButton: true
		loadingMainButton: true
		loadingButton: true
		link: true
		blueText: true
		boldText: true
		activeTopAppBarButton: true
		inactiveTopAppBarButton: true
	}
}

declare module '@mui/material/styles' {
	interface Theme {
		sectionVerticalSpacing: string
		sectionPadding: string
		contentMaxWidthPx: number
		customColors: {
			accent: string
			accent2: string
			darkBackground: string
			mediumBackground: string
			lightBackground: string
			typography: string
			green: string
			accentDecent: string
			primaryElevation1: string
			primaryElevation2: string
			primaryElevation3: string
			primaryElevation4: string
			primaryElevation5: string
		}
		responsiveRem: (rem?: string) => string
	}
	interface ThemeOptions {
		customColors?: {
			accent: string
			accent2: string
			darkBackground: string
			mediumBackground: string
			lightBackground: string
			typography: string
			green: string
			accentDecent: string
			primaryElevation1: string
			primaryElevation2: string
			primaryElevation3: string
			primaryElevation4: string
			primaryElevation5: string
		}
	}
}

const appPrimary = '#ee6520'
const appSecondary = '#1434A4'
const appBackground = '#fbfdff'

const accent = appPrimary
const accentDecent = '#F5F9FD'
const accent2 = appSecondary
const lightBackground = appBackground
const mediumBackground = '#E0E0E0'
const darkBackground = '#575655'
const typography = '#575756'
const white = '#ffffff'
const green = '#2FAC66'

const primaryToWhiteScale = chroma.scale([accent, white]) as chroma.Scale
const primaryElevation5 = primaryToWhiteScale(0.75).hex()
const primaryElevation4 = primaryToWhiteScale(0.8).hex()
const primaryElevation3 = primaryToWhiteScale(0.85).hex()
const primaryElevation2 = primaryToWhiteScale(0.9).hex()
const primaryElevation1 = primaryToWhiteScale(0.95).hex()

const baseButtonStyle = {
	minWidth: '0px',
	textAlign: 'left',
	borderRadius: '0px',
	color: darkBackground,
	backgroundColor: 'none',
	textTransform: 'none',
	padding: '1px',
	fontWeight: 'none',
}

const boldButtonStyle = {
	...baseButtonStyle,
	fontWeight: 'bold',
}

const activeAppBarButtonStyle = {
	'borderRadius': '0.5rem',
	'color': darkBackground,
	'textTransform': 'none',
	'padding': '1px 1rem',
	'fontWeight': 'none',
	'&:hover': {
		backgroundColor: 'transparent',
		color: accent,
	},
}

const themeBreakpoints = {
	values: breakpoints.reduce((acc, cur) => ({ ...acc, [cur.label]: cur.px }), {}),
}

const defaultTheme = {
	...themeBreakpoints,
	customColors: {
		lightBackground,
		mediumBackground,
		accent,
		accent2,
		darkBackground,
		typography,
		green,
		accentDecent,
		primaryElevation1,
		primaryElevation2,
		primaryElevation3,
		primaryElevation4,
		primaryElevation5,
	},
	responsiveRem: undefined,
	contentMaxWidthPx,
	components: {
		MuiCssBaseline: {
			// get rid of scrollbars
			styleOverrides: {
				div: {
					'MsOverflowStyle': 'none', // hide scrollbar in IE
					'scrollbarWidth': 'none', // hide scrollbar in Firefox
					'::-webkit-scrollbar': {
						display: 'none', // hide scrollbar in Chrome, Safari and Opera
					},
				},
			},
		},
		MuiButton: {
			variants: [
				{
					props: { variant: 'mainButton' },
					style: {
						'textTransform': 'none',
						'border': `1px solid ${accent}`,
						'color': white,
						'backgroundColor': accent,
						'&:hover': {
							border: `1px solid ${darkBackground}`,
							color: white,
							backgroundColor: darkBackground,
						},
					},
				},
				{
					props: { variant: 'secondButton' },
					style: {
						'textTransform': 'none',
						'border': `1px solid ${darkBackground}`,
						'color': darkBackground,
						'backgroundColor': 'transparent',
						'&:hover': {
							border: `1px solid ${accent}`,
							color: accent,
							backgroundColor: 'transparent',
						},
					},
				},
				{
					props: { variant: 'altButton' },
					style: {
						'textTransform': 'none',
						'border': `1px solid ${darkBackground}`,
						'color': darkBackground,
						'backgroundColor': white,
						'&:hover': {
							border: `1px solid ${darkBackground}`,
							color: white,
							backgroundColor: darkBackground,
						},
					},
				},
				{
					props: { variant: 'link' },
					style: {
						'borderRadius': '0px',
						'border': 'none',
						'borderBottom': `1px solid ${typography}`,
						'color': darkBackground,
						'backgroundColor': 'none',
						'textTransform': 'none',
						'padding': '1px',
						'fontWeight': 'none',
						'&:hover': {
							backgroundColor: 'transparent',
							color: accent,
							borderBottom: `1px solid ${accent}`,
						},
					},
				},
				{
					props: { variant: 'text' },
					style: {
						...baseButtonStyle,
						'&:hover': {
							backgroundColor: 'transparent',
							color: accent,
						},
					},
				},
				{
					props: { variant: 'boldText' },
					style: {
						...boldButtonStyle,
					},
				},
				{
					props: { variant: 'blueText' },
					style: {
						...baseButtonStyle,
						color: accent,
					},
				},
				// TopAppBarButtons
				{
					props: { variant: 'activeTopAppBarButton' },
					style: {
						...activeAppBarButtonStyle,
						'backgroundColor': white,
						'&:hover': {
							backgroundColor: white,
							color: accent,
						},
					},
				},
				{
					props: { variant: 'inactiveTopAppBarButton' },
					style: baseButtonStyle,
				},
				// LoadingButtons
				{
					props: { variant: 'loadingButton' },
					style: {
						textTransform: 'none',
						borderRadius: '0.25rem',
						border: `1px solid ${mediumBackground}`,
						color: darkBackground,
						backgroundColor: white,
					},
				},
				{
					props: { variant: 'loadingMainButton' },
					style: {
						textTransform: 'none',
						border: `1px solid ${mediumBackground}`,
						color: darkBackground,
						backgroundColor: white,
					},
				},
			],
		},
	},
	palette: {
		primary: {
			main: accent2,
		},
		secondary: {
			main: darkBackground,
		},
	},
	// caution: this also overwrites specific button styles
	typography: {
		allVariants: { color: typography },
	},
}

export const useActiveBreakPoint = (): BreakpointLabel => {
	const { width } = useWindowDimensions()
	return (
		breakpoints.find((bp, index) => {
			const current = bp.px
			const next = breakpoints[index + 1]?.px || Infinity
			return width >= current && width < next
		})?.label || 'md'
	)
}

export const useCreateTheme = () => {
	const activeBreakPoint = useActiveBreakPoint()
	const sizingFactor = breakpoints.find((bp) => bp.label === activeBreakPoint)?.sizingFactor || 1
	const responsiveRem = React.useCallback(
		(rem?: string) => {
			if (!rem) return ''
			const numbers = rem.split(' ').map((r) => Number(r.replace('rem', '')))
			return numbers.map((n) => `${n * sizingFactor}rem`).join(' ')
		},
		[sizingFactor]
	)

	const currentTheme = React.useMemo(
		() =>
			createTheme({
				...defaultTheme,
				responsiveRem: responsiveRem,
			} as MuiThemeOptions),

		[responsiveRem]
	)

	currentTheme.typography.h1 = {
		[currentTheme.breakpoints.up('lg')]: {
			fontSize: '2.5rem',
			lineHeight: '3rem',
		},
		[currentTheme.breakpoints.down('lg')]: {
			fontSize: '2rem',
			lineHeight: '2.5rem',
		},
	}
	currentTheme.typography.h2 = {
		[currentTheme.breakpoints.up('lg')]: {
			fontSize: '2rem',
			lineHeight: '3rem',
		},
		[currentTheme.breakpoints.down('lg')]: {
			fontSize: '1.5rem',
			lineHeight: '2rem',
		},
	}
	currentTheme.typography.h3 = {
		[currentTheme.breakpoints.up('lg')]: {
			fontSize: '1.75rem',
			lineHeight: '2.25rem',
		},
		[currentTheme.breakpoints.down('lg')]: {
			fontSize: '1.25rem',
			lineHeight: '1.75rem',
		},
	}
	currentTheme.typography.h4 = {
		[currentTheme.breakpoints.up('lg')]: {
			fontSize: '1.25rem',
			lineHeight: '1.5rem',
		},
		[currentTheme.breakpoints.down('lg')]: {
			fontSize: '1rem',
			lineHeight: '1.25rem',
		},
	}
	currentTheme.typography.h5 = {
		[currentTheme.breakpoints.up('lg')]: {
			fontSize: '1rem',
			lineHeight: '1.25rem',
		},
		[currentTheme.breakpoints.down('lg')]: {
			fontSize: '0.75rem',
			lineHeight: '1rem',
		},
	}

	return currentTheme
}
