import { BreakpointLabel } from '../typescript-types'

export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT || 'DEVELOPMENT'
export const DEVELOPMENT = ENVIRONMENT === 'DEVELOPMENT'

export const API_BASE_URL = DEVELOPMENT ? `http://localhost:5001` : 'https://www.api.ubimeal.com'
console.log({
	ENVIRONMENT,
	DEVELOPMENT,
	API_BASE_URL,
})

export const API_BASE_URL_GQL = `${API_BASE_URL}/graphql`
export const CONTACT_EMAIL = 'andreas@beyer-development.com'
export const companyName = 'Ubimeal'
export const legalName = 'Beyer Development UG (haftungsbeschränkt)'

export const COMPANY_LOGO = '/ubimeal-logo.png'
export const COMPANY_LOGO_AND_TEXT = '/ubimeal-logo-text.png'
export const COMPANY_TEXT = '/ubimeal-text.png'
export const HERO_MAGE = '/burger-1-min.png'
export const STEP_1 = '/step1-min.png'
export const STEP_2 = '/step2-min.png'
export const STEP_3 = '/step3-min.png'
export const SEVEN_PHONES = '/7screens-min.png'
export const MIN_ORDER_VALUE_RATIO = '/minOrderValueRationBrb.png'

// ----------------------------------------
// zindexes
// ----------------------------------------

// deafult z-indexes of material-ui components:
// mobile stepper: 1000
// fab: 1050
// speed dial: 1050
// app bar: 1100
// drawer: 1200
// modal: 1300
// snackbar: 1400
// tooltip: 1500

// dialog neeeds to go over app bar

export const APP_BAR_ZINDEX = 1100
export const POPPER_ZINDEX = 1103
export const DIALOG_ZINDEX = 1102
export const DIALOG_BACKGROUND_ZINDEX = 1101
export const FLOATING_ACTION_BUTTON_ZINDEX = 1099
export const CARD_BOX_SHADOW = 3
export const CARD_BORDER_RADIUS_REM = 0.5

export const SPACING_MOBILE_REM = 1
export const SPACING_DESKTOP_REM = 2
export const SECTION_WRAPPER_VERTICAL_SPACING_MOBILE_REM = 2
export const SECTION_WRAPPER_VERTICAL_SPACING_DESKTOP_REM = 4

// ----------------------------------------
// MAPBOX
// ----------------------------------------

export const MAPBOX_PUBLIC_TOKEN =
	'pk.eyJ1IjoiYWJleWVyMTIzIiwiYSI6ImNsb2c0dDlhNjE0MjEycXA5d3kzMDF0ZTAifQ.wNm_dX6_8_U4q11r3o5KDQ'

export const breakpoints: { label: BreakpointLabel; px: number; sizingFactor: number }[] = [
	{ label: 'xs', px: 0, sizingFactor: 0.25 },
	{ label: 'sm', px: 300, sizingFactor: 0.5 },
	{ label: 'md', px: 700, sizingFactor: 1 },
	{ label: 'lg', px: 1100, sizingFactor: 1.25 },
	{ label: 'xl', px: 1500, sizingFactor: 2 },
]

export const contentMaxWidthPx = 1440
