import React from 'react'
import { useTheme, styled } from '@mui/material/styles'
import { Box, Grid } from '@mui/material'
import { useLocation } from 'react-router-dom'

import GridContainer from './GridContainer'
import Button from './Button'
import { CONTACT_EMAIL, companyName } from '../consts'

const horizontalSpacing = { xs: 4, sm: 4, md: 6, lg: 6, xl: 6 }
const verticalSpacing = { xs: 4, sm: 4, md: 6, lg: 6, xl: 6 }

const links = [
	{ name: 'Datenschutzerklärung', path: './dse2.pdf' },
	{ name: 'Impressum', path: '/impress' },
	{ name: 'Kontakt', path: `mailto:${CONTACT_EMAIL}` },
]

const Column = styled(Box)(() => ({
	width: '100%',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
}))

const Row = styled(Box)(() => ({
	width: '100%',
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	flexWrap: 'wrap',
}))

const Footer = () => {
	const location = useLocation()

	const theme = useTheme()
	const sectionVerticalSpacing = theme.sectionVerticalSpacing

	const isPlayerRoute = location.pathname.includes('/player/')
	return isPlayerRoute ? null : (
		<Box
			display="flex"
			flexDirection="column"
			width="100%"
			bgcolor={theme.customColors.lightBackground}
			m={`${sectionVerticalSpacing} 0 0 0`}
		>
			<GridContainer horizontalSpacing={horizontalSpacing} verticalSpacing={verticalSpacing}>
				<Grid item xs={12}>
					<Column>
						<Row>
							<Box
								width="100%"
								display="flex"
								flexDirection="column"
								bgcolor={`1px solid ${theme.customColors.lightBackground}`}
								m="0 0 1rem 0"
							/>
						</Row>

						<Row>
							<Button color="secondary" variant="text" m="0 3rem 0 0">
								© 2024 {companyName}
							</Button>
							{links.map((link, index) => (
								<Button
									key={index}
									m="0 3rem 0 0"
									color="secondary"
									variant="text"
									navigateTo={link.path}
									navigateToExternal
								>
									{link.name}
								</Button>
							))}
							<Box flex="1" />
						</Row>
					</Column>
				</Grid>
			</GridContainer>
		</Box>
	)
}

export default Footer
