import React from 'react'
import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'
import type { BoxProps } from '@mui/material/Box'

type ObjectFit = 'fill' | 'cover' | 'contain' | 'none' | 'scale-down'
interface ExtendedBoxProps extends BoxProps {
	src?: string
	navigateTo?: string
	objectFit?: ObjectFit
	disabled?: boolean
	pointer?: boolean
}

const ContentWrapper = styled(Box)`
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
`

const PotentiallPointerBox = styled(Box)<{ pointer?: 'true' }>(({ pointer }) => ({
	'display': 'flex',
	'position': 'relative',
	':hover': {
		cursor: pointer ? 'pointer' : 'default',
	},
}))

const StyledImg = styled('img')<{ objectfit?: ObjectFit; disabled?: boolean }>(
	({ objectfit, disabled }) => ({
		objectFit: objectfit || 'fill',
		opacity: disabled ? 0.3 : 1,
	})
)

const ImageBox = ({
	src,
	navigateTo,
	onClick,
	children,
	width,
	height,
	objectFit,
	disabled,
	pointer,
	...rest
}: ExtendedBoxProps) => {
	const navigate = useNavigate()
	const handleNavigate = React.useCallback(() => {
		navigate(navigateTo as string)
	}, [navigate, navigateTo])

	return (
		<PotentiallPointerBox
			pointer={pointer || Boolean(onClick) || Boolean(navigateTo) ? 'true' : undefined}
			width={width || '100%'}
			height={height}
			onClick={navigateTo ? handleNavigate : onClick}
			{...rest}
		>
			<StyledImg
				objectfit={objectFit}
				disabled={disabled}
				src={src}
				width="100%"
				height={height ? '100%' : 'auto'}
			/>
			<ContentWrapper>{children}</ContentWrapper>
		</PotentiallPointerBox>
	)
}

export default React.memo(ImageBox)
