/*eslint-disable react-perf/jsx-no-jsx-as-prop */
import React from 'react'
import { Route } from 'react-router-dom'

import HomeView from './screens/HomeView'
import NotFoundView from './screens/NotFoundView'
import ImpressView from './screens/ImpressView'
// import DataProtectionView from './screens/DataProtectionView'
// import AnalyticsMapView from './screens/AnalyticsMapView'
// import RelevantRestaurantsView from './screens/RelevantRestaurantsView'

type Route = {
	path: string
	element: JSX.Element
}

export const routes: Route[] = [
	{
		path: '/',
		element: <HomeView />,
	},
	{
		path: '/impress',
		element: <ImpressView />,
	},
	// {
	// 	path: '/data-privacy',
	// 	element: <DataProtectionView />,
	// },
	{
		path: '*',
		element: <NotFoundView />,
	},
	// {
	// 	path: '/map',
	// 	element: <AnalyticsMapView />,
	// },
	// {
	// 	path: '/relevant',
	// 	element: <RelevantRestaurantsView />,
	// },
]

type UserType = 'LOGGED_OUT'

const getRoutes = (type?: UserType) => {
	return type === 'LOGGED_OUT' ? routes : []
}

export default getRoutes
